import React from 'react';
import Dimension from './Dimension/Dimension';
import FeatureGroup from './FeatureGroup/FeatureGroup';
import FeatureTable from '../../../components/FeatureTable/FeatureTable';
import LineSeparator from '../../../components/UI/LineSeparator/LineSeparator'
import ScoringRubric from '../../../components/ScoringRubric/ScoringRubric';

import ViviLogo from '../../../assets/images/virvi-logo.png';

import styles from './Example.module.css';

const Example = props => {

	const noDimensions = props.dimensions.length;
	const dimensions = props.dimensions.map(dimension=>{ return (
		<Dimension 
			dimension={dimension} 
			key={dimension.id} 
			lastChild = {noDimensions === dimension.id+1 ? true : false} 
		/>
	)});

	const featureGroups = props.features.map(group=>{return(
		<FeatureGroup 
			group={group} 
			key={group.groupTitle}
		/>
	)});

	return (
		<section className='bg-alt py-5' id='example'>
			<div className='container py-5' >
				<div className='row py-3'>
					<div className='col-md-8 offset-md-2 text-center'>
						<h2 className={styles.Title}>
							<span className='text-primary'>CUP</span> for building Vivi's MVP
						</h2>
						<p>A viral video contest app</p>
						<img src={ViviLogo} alt='Vivi Logo' className={styles.Logo} />
						<LineSeparator styles={{maxWidth: '150px', margin: '2rem auto', color: '#ff3d00'}}/>
						<p className={styles.Explanation}>
							Vivi is a viral video contest app. Each weekly Vivi contest has a theme, for instance 
							"Best burger eating video". Contestants join a contest by uploading videos. The owner of the video 
							that gets the most views wins. Winners get monetary awards. Vivi makes money buy finding 
							sponsors (eg. Wendy's) for its contests.
						</p>
						<p className={styles.Explanation}>
							For the purposes of this exercise assume that Vivi has not yet launched. Our goal is to 
							figure out what goes in the MVP of the Vivi app and make sure we have a successful launch.
						</p>
						<LineSeparator styles={{maxWidth: '150px', margin: '2rem auto', color: '#ff3d00'}}/>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-10 offset-md-1 text-center'>
						<h3 className='mb-2'>Step 1: CPG, UVP and Pain Points</h3>
						<button 
							className='btn btn-link d-flex align-items-center mx-auto'
							onClick={()=>props.showVideoModal(
								'Step 1: CPG, UVP and Pain Points', 'https://www.youtube.com/embed/OL6ar7lBvYU?controls=0'
							)}
						>
							<span className='material-icons mr-2'>play_circle_filled</span>
							Watch an explanatory video
						</button>
						{dimensions}
						<LineSeparator styles={{maxWidth: '150px', margin: '0 auto 2rem auto', color: '#ff3d00'}}/>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-12 text-center'>
						<h3 className='mb-2'>Step 2: List and group your potential features and tasks</h3>
						<button 
							className='btn btn-link d-flex align-items-center mx-auto mb-4'
							onClick={()=>props.showVideoModal(
								'Step 2: List and group your potential features and tasks', 
								'https://www.youtube.com/embed/c60CsaCzbbA?controls=0'
							)}
						>
							<span className='material-icons mr-2'>play_circle_filled</span>
							Watch an explanatory video
						</button>
						<div className={['card-columns', styles.FeatureGroups].join(' ')}>
							{featureGroups}
						</div>
						<LineSeparator styles={{maxWidth: '150px', margin: '2rem auto', color: '#ff3d00'}}/>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-12 text-center'>
						<h3 className='mb-2'>Step 3: Score your potential features and tasks</h3>
						<div className='d-md-flex align-items-center justify-content-center my-3'>
							<ScoringRubric />
						</div>
					</div>
				</div>
				<FeatureTable features={props.features} showVideoModal={props.showVideoModal}/>
				<LineSeparator styles={{maxWidth: '150px', margin: '2.5rem auto', color: '#ff3d00'}}/>
				<div className='row'>
					<div className='col-md-12 text-center'>
						<h3 className='mb-2'>Step 4: Decide which features and tasks to include</h3>
						<div className='d-flex py-3 mb-3 justify-content-center'>
	            <div className='d-flex mr-4 align-items-center'>
	              <div className={[styles.Rectangle, styles.IncludeBg].join(' ')}></div>
	              <p className='lead m-0'>: Include</p>
	            </div>
	            <div className='d-flex align-items-center'>
	              <div className={[styles.Rectangle, styles.ExcludeBg].join(' ')}></div>
	              <p className='lead m-0'>: Exclude</p>
	            </div>
          	</div>
					</div>
				</div>
				<FeatureTable features={props.features} inclusions showVideoModal={props.showVideoModal} />
				<div className='row'>
					<div className='col-md-10 offset-md-1 text-center'>
						<p className={styles.Benefit}>
							As the above example illustrates, running your features through the 
							<span className='text-primary ml-1'>CUP</span> Method will help you sniff out features that you think 
							you need, but are not necessary <span className='text-muted'>(eg. 'Multiple contest support and contest index (list) page', 'liking videos', 
							'payment automation')</span>. 
						</p>
						<p className={styles.Benefit}>	
							This results not only results is a faster learning curve 
							by getting you to market faster, but it also ensures the cognitive load on your users are lower,
							making your product easier to understand and easier to use. 
						</p>
					</div>
				</div>
				<LineSeparator styles={{maxWidth: '150px', margin: '1.5rem auto', color: '#ff3d00'}}/>
				<div className='row'>
	        <div className='col-md-10 offset-md-1 text-center'>
	          <h3 className='display-4'>In conclusion</h3>
	          <p className={styles.ConclusionPoint}>
	          	With the <span className='text-primary'>CUP</span> Method you get out what you put in.
	          </p>
	          <p className={styles.ConclusionPoint}>
	          	First, you need to setup your dimensions correctly. Second, you need to come up with a 
	          	potential list of features and tasks. Third, you need to evaluate them honestly.
	          </p>
	          <p className={styles.ConclusionPoint}>
	          	If you can do these 3 things correctly, 
	          	the <span className="text-primary">CUP</span> Method will keep you focused 
	          	and significantly speed up your journey from idea to product-market fit.
	          </p>
	        </div>
	      </div>
	      <LineSeparator styles={{maxWidth: '150px', margin: '1.5rem auto 2.5rem auto', color: '#ff3d00'}}/>
			  <div className='row'>
			  	<div className='col-12'>
			      <button 
			      	className='btn btn-link btn-lg p-0 d-flex align-items-center mx-auto'
			      	onClick={()=>props.scrollToElement('about', 1000)}
			      >
							<span className='material-icons mr-2'>expand_more</span>
							About the author
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Example;