import React from 'react';
import Modal from 'react-bootstrap/Modal';

import styles from './VideoModal.module.css';

const VideoModal = props => {
	return (
		<Modal 
			size='lg'
			show={props.showModal} 
			onHide={props.closeModal}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<div className='text-center'>
					<span 
						className={['material-icons', styles.Close].join(' ')}
						onClick={props.closeModal}
					>
						close
					</span>
					<h3>{props.title}</h3>
					<div className={styles.VidWrapper}>
						<iframe
							title='Explanation video'
							className={styles.Video} 
							src={props.videoUrl}
							frameBorder='0' 
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' 
							allowFullScreen
						></iframe>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default VideoModal;