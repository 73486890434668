import React from 'react';

import styles from '../../FeatureTable.module.css';

const Feature = props => {

	let rowBackground = null;
	if (props.inclusion) {
		rowBackground = styles.ExcludeBg
		if (props.feature.include) rowBackground = styles.IncludeBg
	}

	return (
		<div className={['row', 'flex-grow-1', styles.FeatureRow, rowBackground].join(' ')}>
	    <div className={['col-6', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
	      <div>
	        <p className={styles.FeatureTitle}>{props.feature.featureTitle}</p>
	        <p className={styles.Example}>{props.feature.example}</p>
	      </div>
      </div>
      <div className='col-6'>
        <div className='row h-100'>
          <div className={['col-3', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
            <h5 className={styles.Score}>{props.feature.cpg}</h5>
          </div>
          <div className={['col-3', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
            <h5 className={styles.Score}>{props.feature.uvp}</h5>
          </div>
          <div className={['col-3', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
            <h5 className={styles.Score}>{props.feature.pain}</h5>
          </div>
          <div className={['col-3', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
            <h5 className={styles.Score}>{props.feature.cpg+props.feature.uvp+props.feature.pain}</h5>
          </div>
        </div>
      </div>
	  </div>
	)
}

export default Feature;