import React from 'react';

import Facebook from '../../assets/images/facebook-promotion.png';
import Twitter from '../../assets/images/twitter-promotion.png';

const SocialImages = props => {
	return (
		<section className='bg-light py-5'>
			<div className='container my-5 py-5' >
				<div className='row'>
					<div className='col-12'>
						<img src={Facebook} alt='the CUP Method' className='my-5' />
						<img src={Twitter} alt='the CUP Method' className='my-5' />
					</div>
				</div>
			</div>
		</section>
	)
}

export default SocialImages