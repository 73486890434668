import React, {Fragment} from 'react';

const ScoringRubric = props => {
	return (
		<Fragment>
			<div className='row'>
				<div className='col-1 offset-2 offset-sm-3 justify-content-end px-0 d-flex align-items-center'>
					<h4 className='m-0'>0</h4>
				</div>
				<div className='col-1 px-0 justify-content-center d-flex align-items-center'>
					<h4 className='m-0'>-</h4>
				</div>
				<div className='col-8 col-sm-7 pl-2 d-flex text-left align-items-center'>
					<p className='m-0 lead'>Does not help at all</p>
				</div>
			</div>
			<div className='row'>
				<div className='col-1 offset-2 offset-sm-3 justify-content-end px-0 d-flex align-items-center'>
					<h4 className='m-0'>1</h4>
				</div>
				<div className='col-1 px-0 justify-content-center d-flex align-items-center'>
					<h4 className='m-0'>-</h4>
				</div>
				<div className='col-8 col-sm-7 pl-2 d-flex text-left  align-items-center'>
					<p className='m-0 lead'>Helps incidentally</p>
				</div>
			</div>
			<div className='row'>
				<div className='col-1 offset-2 offset-sm-3 justify-content-end px-0 d-flex align-items-center'>
					<h4 className='m-0'>2</h4>
				</div>
				<div className='col-1 px-0 justify-content-center d-flex align-items-center'>
					<h4 className='m-0'>-</h4>
				</div>
				<div className='col-8 col-sm-7 pl-2 d-flex text-left align-items-center'>
					<p className='m-0 lead'>Mostly helpful</p>
				</div>
			</div>
			<div className='row'>
				<div className='col-1 offset-2 offset-sm-3 justify-content-end px-0 d-flex align-items-center'>
					<h4 className='m-0'>3</h4>
				</div>
				<div className='col-1 px-0 justify-content-center d-flex align-items-center'>
					<h4 className='m-0'>-</h4>
				</div>
				<div className='col-8 col-sm-7 pl-2 d-flex text-left align-items-center'>
					<p className='m-0 lead'>Hits the nail on the head</p>
				</div>
			</div>
		</Fragment>
	)


}

export default ScoringRubric;