import React from 'react';
import Feature from './Feature/Feature';

import styles from '../FeatureTable.module.css';

const FeatureGroup = props => {
	
	const features = props.group.features.map(feature=>{return(
		<Feature 
			feature={feature}
			inclusion={props.inclusions}
			key={feature.featureTitle}
		/>
	)});

	let scoringVideoTrigger = null;
	if (props.group.scoringVideo && !props.inclusions) {
		scoringVideoTrigger = (
			<button 
				className='btn btn-link d-flex align-items-center p-0'
				onClick={()=>props.showVideoModal('Explaining how the features were scored', props.group.scoringVideo)}
			>
				<span className='material-icons mr-2'>play_circle_filled</span>
				Explanation
			</button>
		)
	}

	let inclusionVideoTrigger = null;
	if (props.group.inclusionVideo && props.inclusions) {
		inclusionVideoTrigger =	
			<button 
				className='btn btn-link d-flex align-items-center p-0'
				onClick={()=>props.showVideoModal('Explaining the inclusion decisions', props.group.inclusionVideo)}
			>
				<span className='material-icons mr-2'>play_circle_filled</span>
				Explanation
			</button>
	}
	
	return (
		<div className={['row', styles.GroupRow].join(' ')}>
	    <div className={['col-2', 'd-flex', 'align-items-center', styles.BorderRight].join(' ')}>
	      <div>
		      <h5 className={styles.GroupTitle}>{props.group.groupTitle}</h5>
		      {scoringVideoTrigger}
		      {inclusionVideoTrigger}
		    </div>
	    </div>
	    <div className='col-10 d-flex align-content-stretch flex-column'>
	      {features}
	    </div>
	  </div>
	)
}

export default FeatureGroup;