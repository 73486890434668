import React from 'react';

import styles from './LineSeparator.module.css';

const LineSeparator = props => {

	let lineStyle = null;
	if (props.styles){
		lineStyle = {
			borderColor: props.styles.color,
			borderWidth: props.styles.borderWidth,
			width: props.styles.width,
			maxWidth: props.styles.maxWidth,
			margin: props.styles.margin 
		}
	}

	return (
		<div className={styles.LineSeparator} style={lineStyle}></div>
	)
}

export default LineSeparator;