import React from 'react';

import Logo from '../../UI/Logo/Logo';

const Footer = props => {

	const year = new Date().getFullYear();

	return (
		<nav className='navbar, navbar-dark, bg-dark'>
			<div className='container'>
				<div className='row'>
					<div className='col-12 d-flex justify-content-between align-items-center'>
						<Logo />
						<p className='lead m-0 text-white'>&copy; <span>{year}</span></p>
					</div>
				</div>
			</div>
		</nav>
	)
}

export default Footer;