import React from 'react';
import Parser from 'html-react-parser';

import styles from './Dimension.module.css';

const Dimension = props => {

	const lastChild = props.lastChild ? styles.LastChild : null;

	return (
		<div className={['row', 'text-center', 'text-sm-left', 'py-5', styles.Dimension, lastChild].join(' ')}>
	    <div className='col-sm-5'>
	      <h3 className='pb-sm-4 m-0'>{Parser(props.dimension.title)}</h3>
	    </div>
	    <div className='col-sm-7'>
	      <div>{Parser(props.dimension.body)}</div>
	    </div>
	  </div>
	)
}

export default Dimension;