import React from 'react';

import Cup from '../../../assets/images/coffee-cup-transparent.png';

import styles from './Hero.module.css';

const Hero = props => {
	return (
		<section className='bg-light py-5' id='hero'>
			<div className='container my-5 py-5' >
				<div className='row pt-4 pb-2 d-flex align-items-center'>
					<div className='col-md-4 d-flex align-items-center justify-content-center'>
						<img src={Cup} alt='Cup' className={styles.Cup} />
					</div>
					<div className='col-md-8 d-flex align-items-center'>
						<div className='pt-5 text-center text-md-left'>
							<p className={styles.TaglineSetup}>
								A prioritization framework that helps you go from an
							</p>
							<h1 className='text-underline mb-4'>
								idea to product-market fit
							</h1>
							<button 
								className='btn btn-link btn-lg p-0 d-flex align-items-center mx-auto mx-md-0'
								onClick={()=>props.scrollToElement('why', 1000)}
							>
								<span className='material-icons mr-2'>expand_more</span>
								Learn more
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
} 

export default Hero;