import React, {Component, Fragment} from 'react';
import { Element, animateScroll as scrollSpy, scroller } from 'react-scroll';

import MainNavbar from '../../components/Navbars/MainNavbar/MainNavbar';
import Hero from './Hero/Hero';
import Why from './Why/Why';
import How from './How/How';
import Example from './Example/Example';
import About from './About/About';
import Contact from './Contact/Contact';
import VideoModal from './VideoModal/VideoModal';
import Footer from '../../components/Navbars/Footer/Footer';

class Home extends Component {

	state = {
		videoModal: {
			showModal: false,
			title: 'Step 1',
			url: 'https://www.youtube.com/embed/OL6ar7lBvYU?controls=0'
		},
		howItems: [
			{
				id: 0,
				title: `<span class='first-letter'>C</span>urrent Primary Goal`,
				body: `Provide <span class='text-underline'>extreme focus</span>. Set a single goal you can 
				reach in the short-term (think a few weeks to a few months). Then score your tasks and features 
				on how well they help you achieve that goal.`,
			}, 
			{
				id: 1,
				title: `<span class='first-letter'>U</span>nique Value Proposition`,
				body: `What makes you <span class='text-underline'>different</span> from all the alternatives? 
				Score your tasks and features on how well they help deliver your unique value proposition to your
				users.`,
			},
			{
				id: 2,
				title: `<span class='first-letter'>P</span>ain Points`,
				body: `What major problems are your users experiencing? Score your tasks and features on
				how well they <span class='text-underline'>solve problems</span> for your users.`,
			}
		],
		example: {
			dimensions: [
				{
					id: 0,
					title: `<span class='first-letter'>C</span>urrent Primary Goal`,
					body: `<p class='lead m-0'>Get 10,000 unique viewers for one contest.</p>`,
				}, 
				{
					id: 1,
					title: `<span class='first-letter'>U</span>nique Value Proposition`,
					body: `<p class='lead m-0'>A video contest platform that generates excitement for creators
					and viewers through ephemeral contests with monetary awards.</p>`,
				},
				{
					id: 2,
					title: `<span class='first-letter'>P</span>ain Points`,
					body: `
						<p class='lead m-0'>Pain points of creators:</p> 
						<ul>
							<li class='dimension-list-item'>When it comes to promoting your content, you are left to your own devices.</li>
							<li class='dimension-list-item'>Popularity does not always mean meaningful revenues are generated.</li>
						</ul>
						<p class='lead m-0'>Pain points of viewers:</p>
						<ul>
							<li class='dimension-list-item'>Finding great content is like finding needle in a haystack.</li>
							<li class='dimension-list-item'>There is no emotional attachment formed with videos beyond their entertainment value.</li>
						</ul>
					`
				}
			],
			features: [
				{
					groupTitle: 'Platform support',
					features: [
						{
							featureTitle: 'Native app that works on a single platform',
							cpg: 2,
							uvp: 2,
							pain: 1,
							include: false
						},
						{
							featureTitle: 'Hybrid app that works on Android, iPhone and web',
							cpg: 3,
							uvp: 3,
							pain: 1,
							include: true
						}
					]
				},
				{
					groupTitle: 'Account creation and management',
					features: [
						{
							featureTitle: 'Email-password account creation / login / forgot password',
							cpg: 1,
							uvp: 1,
							pain: 1,
							include: false
						},
						{
							featureTitle: 'Facebook account creation',
							cpg: 2,
							uvp: 1,
							pain: 2,
							include: true
						},
						{
							featureTitle: 'Google account creation',
							cpg: 1,
							uvp: 1,
							pain: 1,
							include: false
						},
						{
							featureTitle: 'Account settings',
							cpg: 0,
							uvp: 0,
							pain: 0,
							include: false
						}
					]
				},
				{
					groupTitle: 'Contest interactions',
					scoringVideo: 'https://www.youtube.com/embed/MqPQjwl20nk?controls=0',
					inclusionVideo: 'https://www.youtube.com/embed/KGxpZ3U9m8U?controls=0',
					features: [
						{
							featureTitle: 'Individual contest screen',
							cpg: 2,
							uvp: 3,
							pain: 3,
							include: true
						},
						{
							featureTitle: 'Multiple contest support and contest index (list) screen',
							cpg: 1,
							uvp: 1,
							pain: 1,
							include: false
						},
						{
							featureTitle: 'Contest sharing and promotion',
							cpg: 3,
							uvp: 3,
							pain: 3,
							include: true
						},
						{
							featureTitle: 'Contest leaderboard',
							cpg: 2,
							uvp: 3,
							pain: 2,
							include: true
						},
						{
							featureTitle: 'Previous contests archive',
							cpg: 0,
							uvp: 0,
							pain: 1,
							include: false
						}
					]
				},
				{
					groupTitle: 'Video creation',
					scoringVideo: 'https://www.youtube.com/embed/EG2gfYWa558?controls=0',
					inclusionVideo: 'https://www.youtube.com/embed/VWqDRXYhnOs?controls=0',
					features: [
						{
							featureTitle: 'Video recording',
							cpg: 2,
							uvp: 1,
							pain: 1,
							include: false
						},
						{
							featureTitle: 'Video editing and effects',
							cpg: 2,
							uvp: 2,
							pain: 2,
							include: false
						},
						{
							featureTitle: 'Video uploading',
							cpg: 2,
							uvp: 1,
							pain: 1,
							include: false
						},
						{
							featureTitle: 'Embedding videos from other platforms',
							cpg: 2,
							uvp: 2,
							pain: 2,
							include: true
						},
						{
							featureTitle: 'Creator video sharing and promotion',
							cpg: 3,
							uvp: 2,
							pain: 2,
							include: true
						}
					]
				},
				{
					groupTitle: 'Video interactions',
					features: [
						{
							featureTitle: 'Requiring login to view videos',
							cpg: 0,
							uvp: 0,
							pain: 0,
							include: false
						},
						{
							featureTitle: 'Liking videos',
							cpg: 0,
							uvp: 1,
							pain: 2,
							include: false
						},
						{
							featureTitle: 'Commenting on videos',
							cpg: 0,
							uvp: 1,
							pain: 2,
							include: false
						},
						{
							featureTitle: 'Viewer video sharing and promotion',
							cpg: 3,
							uvp: 3,
							pain: 3,
							include: true
						}
					]
				},
				{
					groupTitle: 'Notifications',
					features: [
						{
							featureTitle: 'Notifications that encourage the uploader to keep promoting their videos',
							example: 'eg. "You are rising in the standings. You are now in [X]th place. You need N more views to get to [X-1]th place. Keep promoting your video, [video_name] to win."',
							cpg: 3,
							uvp: 3,
							pain: 3,
							include: true
						},
						{
							featureTitle: 'Notifications for viewers to keep promoting the videos they\'ve viewed',
							example: 'eg. "The video you\'ve watched, [video_name], is in Xth place. Help it climb up the standings. Promote it now!"',
							cpg: 3,
							uvp: 3,
							pain: 3,
							include: true
						}
					]
				},
				{
					groupTitle: 'Operational',
					features: [
						{
							featureTitle: 'Payment automation for contest winners',
							cpg: 0,
							uvp: 0,
							pain: 1,
							include: false
						},
						{
							featureTitle: 'Admin interface to create and manage contests',
							cpg: 0,
							uvp: 0,
							pain: 0,
							include: false
						}
					]
				}
			]
		}
	}

	scrollToElement = (element, duration) => {
		scroller.scrollTo(element, {
			duration: duration,
			smooth: true,
		});
	}

	showVideoModal = (title, url) => this.setState({videoModal:{showModal:true, title: title, url: url}})
	closeVideoModal = () => this.setState({videoModal:{showModal:false}})

  render(){
  	return (
  		<Fragment>
	  		<MainNavbar scrollToElement={this.scrollToElement}/>
	  		<Hero scrollToElement={this.scrollToElement}/>
	  		<Element name='whyElement'>
	  			<Why scrollToElement={this.scrollToElement}/>
	  		</Element>
		  	<Element name='howElement'>
		  		<How howItems={this.state.howItems} scrollToElement={this.scrollToElement}/>
		  	</Element>
		  	<Element name='exampleElement'>
		  		<Example 
		  			dimensions={this.state.example.dimensions} 
		  			features={this.state.example.features}
		  			scrollToElement={this.scrollToElement}
		  			showVideoModal={this.showVideoModal}
		  		/>
		  	</Element>
		  	<Element name='aboutElement'>
		  		<About />
		  	</Element>
		  	<Element name='contactElement'>
		  		<Contact />
		  	</Element>
		  	<Footer />
		  	<VideoModal 
		  		showModal={this.state.videoModal.showModal}
		  		closeModal={this.closeVideoModal} 
		  		title={this.state.videoModal.title}
		  		videoUrl={this.state.videoModal.url}
		  	/>
	  	</Fragment>
	  );
	}
}

export default Home;