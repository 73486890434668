import React from 'react';

import styles from './About.module.css';

import Profile from '../../../assets/images/profile-circle.png';

const About = props => {
	return (
		<section className='bg-light pt-5 pb-2' id='about'>
			<div className='container' >
				<div className='row'>
					<div className='col-md-10 offset-md-1 text-center'>
						<h2>Hello world,</h2>
						<img src={Profile} alt='Can B.' className={styles.ProfilePic} />
						<p className={styles.AboutP}>
							I'm Can (pronounced John). I am an entrepreneur with 2 exits, turned product 
							leader. I have strong views on how startups should be run. I've started sharing these views 
						  beginning with The <span className='text-primary'>CUP</span> Method. If you 
							like what you see, give me a follow on twitter.  
						</p>
						<a 
							href='https://twitter.com/intent/follow?screen_name=canbabaoglu&original_referer=https://cupmethod.com/'
							className={['btn', 'btn-primary', styles.TwitterButton].join(' ')}
						>
							<div className='d-flex align-items-center pr-2'>
								<div style={{display:'flex', flexWrap:'wrap'}}>
									<div style={{textDecoration:'none',border:'0',width:'36px',height:'36px',padding:'0',margin:'0',color:'#ffffff'}}>
										<svg className="niftybutton-twitter" style={{display:'block', fill:'currentColor'}} data-tag="twi" data-name="Twitter" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
	    								<path d="M419.6 168.6c-11.7 5.2-24.2 8.7-37.4 10.2 13.4-8.1 23.8-20.8 28.6-36 -12.6 7.5-26.5 12.9-41.3 15.8 -11.9-12.6-28.8-20.6-47.5-20.6 -42 0-72.9 39.2-63.4 79.9 -54.1-2.7-102.1-28.6-134.2-68 -17 29.2-8.8 67.5 20.1 86.9 -10.7-0.3-20.7-3.3-29.5-8.1 -0.7 30.2 20.9 58.4 52.2 64.6 -9.2 2.5-19.2 3.1-29.4 1.1 8.3 25.9 32.3 44.7 60.8 45.2 -27.4 21.4-61.8 31-96.4 27 28.8 18.5 63 29.2 99.8 29.2 120.8 0 189.1-102.1 185-193.6C399.9 193.1 410.9 181.7 419.6 168.6z"></path>
										</svg>
									</div>
								</div>
								Follow
							</div>
						</a>
					</div>
				</div>
			</div>
		</section>
	)
}

export default About;