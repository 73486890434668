import React from 'react';
import FeatureGroup from './FeatureGroup/FeatureGroup';

import styles from './FeatureTable.module.css';

const FeatureTable = props => {

	const featureGroups = props.features.map(group=>{return(
		<FeatureGroup 
			group={group} 
			inclusions={props.inclusions}
			key={group.groupTitle}
			showVideoModal={props.showVideoModal}
		/>
	)});

	return (
		<section className={styles.FeatureTable}>
			<div className={['col-12', styles.TableWrapper].join(' ')}>
				<div className={['row', styles.HeaderRow].join(' ')}>
		      <div className={['col-2', 'd-flex', 'align-items-center', styles.BorderRight].join(' ')}>
		        <h5 className={styles.HeaderTitle}>Group</h5>
		      </div>
		      <div className='col-10'>
		        <div className='row'>
		          <div className={['col-6', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
		            <h5 className={styles.HeaderTitle}>Features / tasks</h5>
		          </div>
		          <div className='col-6'>
		            <div className='row'>
		              <div className={['col-3', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
		                <h5 className={styles.HeaderTitle}>Current primary goal</h5>
		              </div>
		              <div className={['col-3', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
		                <h5 className={styles.HeaderTitle}>Unique value prop</h5>
		              </div>
		              <div className={['col-3', 'd-flex', 'align-items-center', 'py-1', styles.BorderRight].join(' ')}>
		                <h5 className={styles.HeaderTitle}>Pain points</h5>
		              </div>
		              <div className='col-3 d-flex align-items-center py-1'>
		                <h5 className={styles.HeaderTitle}>CUP Score</h5>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		    {featureGroups}
		  </div>
	  </section>
	)
}

export default FeatureTable;