import React from 'react';
import Logo from '../../UI/Logo/Logo';

const MainNavbar = props => {
	return (
		<nav className='navbar navbar-expand-lg navbar-dark bg-main-navbar fixed-top' id='mainNav'>
	    <div className='container'>
	      <Logo />
	      <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarResponsive' aria-controls='navbarResponsive' aria-expanded='false' aria-label='Toggle navigation'>
	        <span className='navbar-toggler-icon'></span>
	      </button>
	      <div className='collapse navbar-collapse' id='navbarResponsive'>
	        <ul className='navbar-nav ml-auto'>
	        	<li className='nav-item'>
	            <span 
	            	className='nav-link js-scroll-trigger active' 
	            	href='#hero'
	            	onClick={()=>props.scrollToElement('hero', 1000)}
	            >
	            	Home
	            </span>
	          </li>
	          <li className='nav-item'>
	            <span 
	            	className='nav-link js-scroll-trigger' 
	            	href='#why'
	            	onClick={()=>props.scrollToElement('why', 1000)}
	            >
	            	Why
	            </span>
	          </li>
	          <li className='nav-item'>
	            <span 
	            	className='nav-link js-scroll-trigger' 
	            	href='#how'
	            	onClick={()=>props.scrollToElement('how', 1000)}
	            >
	            	How
	            </span>
	          </li>
	          <li className='nav-item'>
	            <span 
	            	className='nav-link js-scroll-trigger' 
	            	href='#example'
	            	onClick={()=>props.scrollToElement('example', 1000)}
	            >
	            	Example
	            </span>
	          </li>
	          <li className='nav-item'>
	            <span 
	            	className='nav-link js-scroll-trigger' 
	            	href='#about'
	            	onClick={()=>props.scrollToElement('about', 1000)}
	            >
	            	About
	            </span>
	          </li>
	          <li className='nav-item d-flex align-items-center'>
	            <a 
	            	className='twitter-follow-button'
							  href='https://twitter.com/CanBabaoglu'
							  data-size='large'
							  data-show-count='false'
							  data-show-screen-name='false'
							>
								Follow
							</a>
	          </li>
	        </ul>
	      </div>
	    </div>
	  </nav>
	);
}

export default MainNavbar;