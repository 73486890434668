import React from 'react';
import LineSeparator from '../../../../components/UI/LineSeparator/LineSeparator';

const FeatureGroup = props => {
	
	const features = props.group.features.map((feature, index)=>{
		let bottomBorder = <LineSeparator styles={{borderWidth: '1px', maxWidth: '60px', margin: '0.7rem auto', color: '#D9DEE2'}}/>
		if (index === (props.group.features.length - 1) ) bottomBorder = null;
		return (
			<li key={index}>
				<p className='m-0'>{feature.featureTitle}</p>
				{bottomBorder}
			</li>
		)
	})

	return (
		<div className='card p-2 text-center'>
			<div className='card-body'>
	      <h4 className='card-title'>{props.group.groupTitle}</h4>
	      <LineSeparator styles={{borderWidth: '2px', maxWidth: '100%', margin: '1rem 0', color: '#D9DEE2'}}/>
	      <ul className='list-unstyled'>
	      	{features}
	      </ul>
	    </div>
		</div>
	)
}

export default FeatureGroup;