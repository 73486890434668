import React, {Component} from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import {createBrowserHistory} from 'history';

import Home from '../Home/Home';
import SocialImages from '../SocialImages/SocialImages';

// import {gtagUpdateConfig} from '../../shared/gtagIntegration';

const history = createBrowserHistory()
history.listen(location => {  
  window.scrollTo(0,0);
  //gtagUpdateConfig({page_path: location.pathname});
});

class App extends Component {

  render(){

    const routes = (
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/social-images' exact component={SocialImages} />
        <Redirect to='/' />
      </Switch>
    )

    return (
      <Router history={history}>
        <div>
          {routes}
        </div>
      </Router>
    )
  }
}

export default App;
