import React from 'react';
import LineSeparator from '../../../components/UI/LineSeparator/LineSeparator'

import styles from './Contact.module.css';

const Contact = props => {
	return (
		<section className='bg-light' id='contact'>
			<div className='container' >
				<div className='row'>
					<div className='col-md-10 offset-md-1 text-center'>
						<LineSeparator styles={{maxWidth: '200px', margin: '4rem auto'}}/>
						<h2 className='mb-3'>Want to chat?</h2>
						<p className={styles.Tagline}>
							Need some help using the <span className='text-primary'>CUP</span> Method? Or just want to
							talk startups or product? Drop me a line.
						</p>
						<div className='row py-4'>
							<div className='col-sm-6'>
								<p className='lead mb-0'>DM me on twitter</p>
								<h4><a href='https://twitter.com/CanBabaoglu' className={styles.Link}>@CanBabaoglu</a></h4> 
							</div>
							<div className='col-sm-6'>
								<p className='lead mb-0'>Send me an email</p>
								<h4><a href='mailto:babaoglu.can@gmail.com' className={styles.Link}>babaoglu.can@gmail.com</a></h4> 
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Contact;