import React from 'react';
import HowItem from './HowItem/HowItem';
import ScoringRubric from '../../../components/ScoringRubric/ScoringRubric';
import LineSeparator from '../../../components/UI/LineSeparator/LineSeparator'

import styles from './How.module.css';

const How = props => {

	const noHowItems = props.howItems.length;
	const howItems = props.howItems.map(item=><HowItem item={item} key={item.id} lastChild = {noHowItems === item.id+1 ? true : false} />)

	return (
		<section className='bg-light py-5' id='how'>
			<div className='container py-5' >
				<div className='row py-3'>
					<div className='col-md-10 offset-md-1 text-center'>
						<h2 className={styles.Title}>
							Use the three dimensions of <span className='text-primary'>CUP</span><br />
							to <span className='text-underline'>score</span> and <span className='text-underline'>prioritize</span><br />
							features and tasks
						</h2>
						<LineSeparator styles={{maxWidth: '200px', margin: '4rem auto'}}/>
						{howItems}
						<LineSeparator styles={{maxWidth: '200px', margin: '2rem auto'}}/>
						<h3>Scoring rubric</h3>
						<ScoringRubric />
						<LineSeparator styles={{maxWidth: '200px', margin: '2rem auto 4rem auto'}}/>
						<button 
							className='btn btn-link btn-lg p-0 d-flex align-items-center mx-auto'
							onClick={()=>props.scrollToElement('example', 1000)}
						>
							<span className='material-icons mr-2'>expand_more</span>
							Work through an example
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}

export default How;