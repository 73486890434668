import React from 'react';
import LineSeparator from '../../../components/UI/LineSeparator/LineSeparator'

import styles from './Why.module.css';

import CupPosition from '../../../assets/images/cup-position.png';

const Why = props => {
	return (
		<section className='bg-alt py-5' id='why'>
			<div className='container py-5' >
				<div className='row py-3'>
					<div className='col-md-10 offset-md-1 text-center'>
						<h2 className={styles.Title}>Does the world need another prioritization framework?</h2>
						<p className={styles.Answer}>The whole world may not, but entrepreneurs do.</p>
						<LineSeparator styles={{maxWidth: '200px', margin: '4rem auto'}}/>
						<p className={styles.MainPoint}>
							Most prioritization frameworks are designed for established product organizations, not 
							entrepreneurs. They assume that you are further along the startup lifecycle, not just 
							starting off. 
						</p>
						<p className={styles.MainPoint}>
							The <span className='text-primary'>CUP</span> Method is for entrepreneurs who are starting 
							off with a new project.
						</p>
						<img src={CupPosition} alt='Position' className={styles.CupPosition} />
						<p className={styles.Credit}>
							The startup lifecycle approach is inspired by 
							<a 
								target='_blank' 
								rel='noopener noreferrer' 
								href='https://www.forentrepreneurs.com/setting-the-startup-accelerator-pedal/'
								className={styles.Link}
							>
								Setting the Startup Accelerator Pedal
							</a>
							written by 
							<a 
								target='_blank' 
								rel='noopener noreferrer' 
								href='https://twitter.com/bostonvc/'
								className={styles.Link}
							>
								David Skok
							</a>
							.
						</p>
						<p className={styles.MainPoint}>
							The <span className='text-primary'>CUP</span> Method is designed to help entrepreneurs 
							address user pains, not build vanity features.
						</p>
						<p className={styles.MainPoint}>
							The <span className='text-primary'>CUP</span> Method is designed to help entrepreneurs build a unique solution, not build another copycat. 
						</p>
						<p className={styles.MainPoint}>
							The <span className='text-primary'>CUP</span> Method is designed to help entrepreneurs set and achieve short-term goals in service of 
							reaching their ultimate vision, not get lost dreaming about what happens 3 ,5, 10 years 
							from now. 
						</p>
						<button 
							className='btn btn-link btn-lg p-0 d-flex align-items-center mx-auto mt-5'
							onClick={()=>props.scrollToElement('how', 1000)}
						>
							<span className='material-icons mr-2'>expand_more</span>
							How it works
						</button>
					</div>
				</div>
			</div>
		</section>
	)

}

export default Why;