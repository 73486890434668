import React from 'react';
import {Link} from 'react-router-dom';

import Cup from '../../../assets/images/logo.png';

import styles from './Logo.module.css';

const Logo = props => {

	return (
		<Link to='/' className='navbar-brand'>
			<div className={styles.Logo}>
				<img src={Cup} alt='Cup' className={styles.Cup} />
				<span className={styles.LogoAccent}>CUP</span>
				<span>Method</span>
			</div>
		</Link>
	);

}

export default Logo;