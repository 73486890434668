import React from 'react';
import Parser from 'html-react-parser';

import styles from './HowItem.module.css';

const HowItem = props => {

	const lastChild = props.lastChild ? styles.LastChild : null;

	return (
		<div className={['row', 'text-center', 'text-sm-left', 'py-5', styles.HowItem, lastChild].join(' ')}>
	    <div className='col-sm-5'>
	      <h3 className='pb-sm-4 m-0'>{Parser(props.item.title)}</h3>
	    </div>
	    <div className='col-sm-5'>
	      <p className='lead m-0'>{Parser(props.item.body)}</p>
	    </div>
	    <div className='col-sm-2 text-center'>
	      <h2 className='m-0'>0-3</h2>
	      <p className='m-0'>points</p>
	    </div>
	  </div>
	);
}

export default HowItem;